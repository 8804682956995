import React, { useEffect, useRef, useState } from 'react'
import CarePathLogo from "../assets/images/CarePathLogo.png"
import butterfly from "../assets/images/butterfly.png"
import { MdOutlineDashboard } from "react-icons/md";
import { PiBuildingOfficeThin } from "react-icons/pi";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { LiaUserTieSolid } from "react-icons/lia";
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineUser } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { Dropdown } from "react-bootstrap";
import { FaUsers } from "react-icons/fa6";

const Navbar = () => {
    const [collapse,setCollapse] = useState(true)
    const {pathname} = useLocation()
    const navigate = useNavigate()
    const [dropdownOProfile, setDropdownProfile] = useState(false);
    const dropdownRefProfile = useRef(null);
    const toggleDropdownProfile = () => {
        setDropdownProfile(!dropdownOProfile);

      };
      const closeDropdownProfile = () => {
        setDropdownProfile(false);
      };
      useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRefProfile.current && !dropdownRefProfile.current.contains(event.target)) {
                setDropdownProfile(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRefProfile]);
      const logot=()=>{
        sessionStorage?.clear()
        navigate("/")
        
      }
    const userName = `${sessionStorage?.getItem("firstName") + " " + sessionStorage?.getItem("lastName")}`
    const role = (sessionStorage?.getItem("roleId")==1&&"Admin")||(sessionStorage?.getItem("roleId")==2&&"Manager")||(sessionStorage?.getItem("roleId")==3&&"Organization")
    console?.log(pathname,"pathname")
    

    const NavItems = [{ id: "1", icon: <MdOutlineDashboard size={24}/>, name: "Dashboard", path: "dashboard" }, { id: "2", icon: <PiBuildingOfficeThin size={24}/>, name: "View Companies", path: "viewCompanies" },
    { id: "3", icon: <MdOutlineBusinessCenter size={24}/>, name: "View Jobs", path: "viewJobs" }, { id: "4", icon: <LiaUserTieSolid size={24}/>, name: "View Applicants", path: "viewApplicants" },
    { id: "5", icon: <FaUsers size={24}/>, name: "View Users", path: "viewusers" }]
    return (
        <div className='container-fluid' style={{ backgroundColor: "#F5F6FE", height: '100vh', overflow: 'hidden' }}>
            <div className='row'>
                {/* Sidebar */}
                <div className='sidebar p-0' style={{
                    width: collapse ? "250px" : "80px",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    overflowY: 'auto'
                }}>
                    <div className='text-center py-3 sidebar' onClick={() => setCollapse(!collapse)}>
                        <img src={CarePathLogo} className='img-fluid' style={{ width: "80%", cursor: "pointer" }} />
                    </div>

                    {NavItems?.map((v) => {
                        return (
                            <NavLink key={v?.id} to={v?.path} className={({ isActive }) => isActive ? `sidebar d-flex gap-2 p-3 remove-Link navbaractivetext ${!collapse ? "text-center" : ""}` : `sidebar d-flex gap-2 p-3 remove-Link navbartext ${!collapse ? "text-center" : ""}`}>
                                <div className='col-auto'>{v?.icon}</div>
                                {collapse && <div className='sidebar col-auto'>{v?.name}</div>}
                            </NavLink>
                        );
                    })}
                </div>

                {/* Right content */}
                <div className='col py-3 px-4 ' style={{
                    marginLeft: collapse ? "250px" : "80px",
                    // padding: "0 20px",
                    transition: 'margin-left 0.3s ease',
                    overflowY: "auto",
                    height: '100vh'

                }}>
                    <div className='row d-flex justify-content-between py-3'>
                        <div className='col-auto' style={{ fontSize: "25px", fontWeight: 600 }}>
                            {(pathname == "/admin/viewusers" && "View Users") ||(pathname == "/admin/viewCompanies" && "View Companies") || (pathname == "/admin/dashboard" && "Dashboard")|| (pathname == "/admin/viewJobs" && "View Jobs")|| (pathname == "/admin/viewApplicants" && "View Applicants")}
                        </div>
                        
                        <div className='col-auto px-3'>
                            <div className='row align-items-center'>
                                <div className='col-auto bg-white p-2' style={{ borderRadius: "50px", color: "rgba(111, 111, 111, 1)" }}>
                                    <IoMdNotificationsOutline size={30} />
                                </div>
                                <div className='col-auto'>
                                    <div style={{ fontSize: "18px", fontWeight: 600, color: "rgba(0, 0, 0, 1)" }}>{userName} </div>
                                    <div className='text-end' style={{ fontSize: "12px", color: "rgba(111, 111, 111, 1)" }}>{`[ ${role} ]`}</div>
                                </div>
                                {/* <div className='col-auto p-2' style={{ borderRadius: "50px", backgroundColor: "rgb(217, 217, 217)", color: "rgba(111, 111, 111, 1)" }}>
                                    <AiOutlineUser size={30} />
                                </div> */}
                                <Dropdown
                                    show={dropdownOProfile}
                                    onHide={closeDropdownProfile}
                                    ref={dropdownRefProfile}
                                    className='col-auto  pointer p-0'
                                >
                                    <Dropdown.Toggle
                                        variant="none"
                                        id="user-menu"
                                        style={{ border: "none" }}
                                        onClick={toggleDropdownProfile}
                                    >
                                        <div style={{ borderRadius: "50px", backgroundColor: "rgb(217, 217, 217)", color: "rgba(111, 111, 111, 1)" }} className='p-2 col-auto'>
                                        <AiOutlineUser title="Profile" size={30}  />
                                        </div>
                                        
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{
                                        width: "243px", height: "122px", boxShadow: "2px 10px 30px #0000001A",
                                        borderRadius: "3px", marginTop: "5px", right: "0px"
                                    }}>
                                        <Dropdown.ItemText style={{ padding: "0px" }}>
                                            <div style={{ height: "52px", fontWeight: "400", fontFamily: "Roboto" }}
                                                className="p-2 f16 py-3 profile-hover pointer"
                                                // onClick={() =>
                                                //     navigate("/outpatientpro/facility/account/details/")
                                                // }
                                            >
                                                
                                                My Profile
                                            </div>
                                            <div
                                                style={{ height: "52px", fontWeight: "400", fontFamily: "Roboto" }}
                                                className="p-2 mt-2 f16 profile-hover pointer"
                                                onClick={logot}
                                            >
                                                
                                                Logout
                                            </div>
                                        </Dropdown.ItemText>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className='col-auto pointer p-0' style={{ color: "rgba(111, 111, 111, 1)" }} onClick={()=>setDropdownProfile(!dropdownOProfile)}>
                                    <IoIosArrowDown size={30} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
        </div>
    );

}
export default Navbar
