
const initialState = {
    setSearch:"",
    setLocationSearch:""
};
export const Reducer = (state=initialState,action) => {

  switch (action?.type) {
    case "SearchJobs": return {...state,setSearch:action?.data}
    case "SearchLocation": return {...state,setLocationSearch:action?.data}

  
    default:return state
  }
}