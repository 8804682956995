



import React, { Suspense, lazy } from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Navbar from './sharecomponents/Navbar';
const RoutingConfig=lazy(()=>import("./modules/RoutingConfig"))

const App=()=> {
 
  return (
<>
<ToastContainer/>


  <BrowserRouter >
  
  <Suspense fallback={()=><>Loading...</>}>
  <RoutingConfig/>
  </Suspense>

  </BrowserRouter>
</>
  )
}

export default App;

