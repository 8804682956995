import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from './redux/Store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // root.render(
    <Provider store={Store}>
   <App />
    </Provider>
     
   
  // );
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);
reportWebVitals();
